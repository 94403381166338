import React from 'react';
import PropTypes from 'prop-types';
import { omit } from 'lodash';

import { bem } from '@bedrockio/pages/utils';

import './container.less';

@bem
export default class Container extends React.Component {
  getModifiers() {
    const { narrow } = this.props;
    return [narrow ? 'narrow' : null];
  }

  render() {
    const props = omit(this.props, Object.keys(Container.propTypes));
    return <div {...props} className={this.getBlockClass()} />;
  }
}

Container.propTypes = {
  narrow: PropTypes.bool,
};
