import { hydrateRoot, createRoot } from 'react-dom/client';

import React from 'react';

import { PageProvider } from '@bedrockio/pages';
import { hasToken } from '@bedrockio/pages/utils';

import App from './App';

const root = document.getElementById('root');

if (hasToken()) {
  const Editor = React.lazy(() => import('@bedrockio/pages/editor'));
  createRoot(root).render(
    <PageProvider>
      <App />
      <Editor />
    </PageProvider>,
  );
} else {
  hydrateRoot(
    root,
    <PageProvider>
      <App />
    </PageProvider>,
  );
}
